import * as React from 'react';
import { navigate } from 'gatsby';

import { BaseComponentSettings, ComponentSettingsContextProvider } from '@context';
import {
	CookieBannerEntityResponse,
	FooterEntityResponse,
	HeaderEntityResponse,
	MenuEntityResponse,
	PageEntity,
	PopupEntityResponse,
	SectionEntity,
	SeoEntityResponse,
} from '@schema';
import SectionRendererTemplate from '@templates/renderer/section-renderer';

import { Seo } from '../../components/shared/seo';
import MainLayout from '../../layouts/main-layout';

interface PageRendererProps {
	pageContext: {
		page: PageEntity;
		menu: MenuEntityResponse;
		header: HeaderEntityResponse;
		footer: FooterEntityResponse;
		ctaPopup: PopupEntityResponse;
		componentSettings: BaseComponentSettings;
		cookieBanner: CookieBannerEntityResponse;
		seo: SeoEntityResponse;
		theme: string;
		reviewsFilters: any;
	};
}

const PageRendererTemplate = (props: PageRendererProps) => {
	const { page, menu, header, footer, componentSettings, cookieBanner, seo, theme, ctaPopup } =
		props.pageContext;
	const { GlobalMeta } = seo.data.attributes;

	if (!page.attributes) {
		return navigate('/404');
	}

	const { sections } = page.attributes;

	const componentSettingsWithPageDetails = {
		...componentSettings,
		slug: page.attributes.url,
	};

	const orderedSections = sections?.data;

	function isFaq(obj: any): obj is SectionEntity {
		return obj && obj.attributes && obj.attributes.name === 'main-faq';
	}

	return (
		<MainLayout
			menu={menu}
			header={header}
			footer={footer}
			cookieBanner={cookieBanner}
			overflow="hidden"
			theme={theme}
			popup={ctaPopup}
		>
			<Seo
				meta={page.attributes.meta}
				globalMeta={GlobalMeta}
				imagesPreload={[
					orderedSections[0]?.attributes?.backgroundImage?.imageDefault?.data?.attributes?.url,
				]}
				url={page.attributes.url}
				faq={orderedSections.some((obj) => isFaq(obj)) && orderedSections.find((obj) => isFaq(obj))}
			/>
			<ComponentSettingsContextProvider componentSettings={componentSettingsWithPageDetails}>
				{orderedSections?.map((section, k) => (
					<SectionRendererTemplate key={k} section={section} />
				))}
			</ComponentSettingsContextProvider>
		</MainLayout>
	);
};

export default PageRendererTemplate;
